import {Link}     from "@interactivevision/visitor-react";
import classNames from "classnames";
import React      from "react";
import styles     from "./lang-switcher.module.scss"


const LangSwitcher = () => {
  return (
    <div className={styles.container}>
      <Link href="#" title="PL" className={classNames(styles.item, styles.active)}>PL</Link>
      <Link href="#" title="EN" className={classNames(styles.item)}>EN</Link>
    </div>
  );
};

export default LangSwitcher;
