export const mapStyles = [
  {
    "featureType": "administrative",
    "elementType": "all",
    "stylers":     [
      {
        "saturation": "-100",
      },
    ],
  },
  {
    "featureType": "administrative.province",
    "elementType": "all",
    "stylers":     [
      {
        "visibility": "off",
      },
    ],
  },
  {
    "featureType": "landscape",
    "elementType": "all",
    "stylers":     [
      {
        "saturation": -100,
      },
      {
        "lightness": 65,
      },
      {
        "visibility": "on",
      },
      {
        "color": "#fbfbfb",
      },
    ],
  },
  {
    "featureType": "landscape.man_made",
    "elementType": "geometry.fill",
    "stylers":     [
      {
        "visibility": "on",
      },
      {
        "color": "#f6f6f6",
      },
    ],
  },
  {
    "featureType": "landscape.man_made",
    "elementType": "geometry.stroke",
    "stylers":     [
      {
        "visibility": "on",
      },
      {
        "color": "#000000",
      },
    ],
  },
  {
    "featureType": "landscape.man_made",
    "elementType": "labels.text",
    "stylers":     [
      {
        "visibility": "off",
      },
    ],
  },
  {
    "featureType": "landscape.man_made",
    "elementType": "labels.icon",
    "stylers":     [
      {
        "visibility": "off",
      },
    ],
  },
  {
    "featureType": "poi",
    "elementType": "all",
    "stylers":     [
      {
        "saturation": -100,
      },
      {
        "lightness": "50",
      },
      {
        "visibility": "simplified",
      },
    ],
  },
  {
    "featureType": "poi",
    "elementType": "labels.text",
    "stylers":     [
      {
        "visibility": "off",
      },
    ],
  },
  {
    "featureType": "poi",
    "elementType": "labels.icon",
    "stylers":     [
      {
        "visibility": "off",
      },
    ],
  },
  {
    "featureType": "road",
    "elementType": "all",
    "stylers":     [
      {
        "saturation": "-100",
      },
    ],
  },
  {
    "featureType": "road",
    "elementType": "geometry.fill",
    "stylers":     [
      {
        "color": "#c3c3c3",
      },
    ],
  },
  {
    "featureType": "road",
    "elementType": "labels.text",
    "stylers":     [
      {
        "visibility": "off",
      },
    ],
  },
  {
    "featureType": "road",
    "elementType": "labels.icon",
    "stylers":     [
      {
        "visibility": "off",
      },
    ],
  },
  {
    "featureType": "road.highway",
    "elementType": "all",
    "stylers":     [
      {
        "visibility": "off",
      },
    ],
  },
  {
    "featureType": "road.highway",
    "elementType": "labels.text",
    "stylers":     [
      {
        "visibility": "off",
      },
    ],
  },
  {
    "featureType": "road.arterial",
    "elementType": "all",
    "stylers":     [
      {
        "lightness": "30",
      },
      {
        "visibility": "on",
      },
    ],
  },
  {
    "featureType": "road.arterial",
    "elementType": "labels.text",
    "stylers":     [
      {
        "visibility": "off",
      },
    ],
  },
  {
    "featureType": "road.arterial",
    "elementType": "labels.icon",
    "stylers":     [
      {
        "visibility": "off",
      },
    ],
  },
  {
    "featureType": "road.local",
    "elementType": "all",
    "stylers":     [
      {
        "lightness": "40",
      },
      {
        "visibility": "simplified",
      },
    ],
  },
  {
    "featureType": "road.local",
    "elementType": "labels.text",
    "stylers":     [
      {
        "visibility": "off",
      },
    ],
  },
  {
    "featureType": "road.local",
    "elementType": "labels.icon",
    "stylers":     [
      {
        "visibility": "off",
      },
    ],
  },
  {
    "featureType": "transit",
    "elementType": "all",
    "stylers":     [
      {
        "saturation": -100,
      },
      {
        "visibility": "simplified",
      },
    ],
  },
  {
    "featureType": "transit",
    "elementType": "labels.icon",
    "stylers":     [
      {
        "visibility": "off",
      },
    ],
  },
  {
    "featureType": "transit.line",
    "elementType": "geometry.fill",
    "stylers":     [
      {
        "visibility": "off",
      },
    ],
  },
  {
    "featureType": "transit.line",
    "elementType": "labels.icon",
    "stylers":     [
      {
        "visibility": "off",
      },
    ],
  },
  {
    "featureType": "water",
    "elementType": "geometry",
    "stylers":     [
      {
        "hue": "#ffff00",
      },
      {
        "lightness": -25,
      },
      {
        "saturation": -97,
      },
    ],
  },
  {
    "featureType": "water",
    "elementType": "geometry.fill",
    "stylers":     [
      {
        "visibility": "on",
      },
      {
        "color": "#e2e2e2",
      },
    ],
  },
  {
    "featureType": "water",
    "elementType": "labels",
    "stylers":     [
      {
        "lightness": -25,
      },
      {
        "saturation": -100,
      },
    ],
  },
];
