import styles          from "./base.module.scss";
import {FooterContact} from "./partials/footer-contact";
import {Navbar}        from "./partials/navbar";


export const contact = ({children}) => {
  return (
    <>
      <Navbar/>
      <main className={styles.container}>
        {children}
      </main>
      <FooterContact/>
    </>
  );
};
