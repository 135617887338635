import {route}             from "@interactivevision/routing";
import {$redirect}         from "@interactivevision/visitor";
import {$get}              from "@interactivevision/visitor";
import {Link, useLocation} from "@interactivevision/visitor-react";
import {useShared}         from "@interactivevision/visitor-react";
import {useState}          from "react";
import {useEffect}         from "react";
import {useRef}            from "react";
import {FunctionComponent} from "react";
import React               from "react";
import LangSwitcher        from "../../components/lang-switcher";
import Socials             from "../../components/socials";
import styles              from "./navbar-mobile.module.scss";


type Props = {
  active: boolean;
  navigation: any;
  setActive: () => void;
}

const NavbarMobile: FunctionComponent<Props> = ({active, setActive}) => {
  const ref    = useRef<any>(null);
  // @ts-ignore
  const {menu} = useShared();

  const handleNavigate = (url) => {
    $get(url).then(() => setActive());
  };

  return (
    <div ref={ref} style={{height: `${!active ? "0px" : `${ref?.current.scrollHeight}px`}`}} className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.navigation}>
          {menu?.menu_top.map((element, index) => (
            <div className={styles.item} onClick={() => handleNavigate(element.url)} key={index}>{element.name}</div>
          ))}
        </div>
        <div className={styles.footer}>
          <Socials/>
          <LangSwitcher/>
        </div>
      </div>
    </div>
  );
};

export default NavbarMobile;
