import {route}               from "@interactivevision/routing";
import {$t}                  from "@interactivevision/translations";
import {Link}                from "@interactivevision/visitor-react";
import {FunctionComponent}   from "react";
import React                 from "react";
import {Offer}               from "../../../admin/Api/Models/Offer";
import {useAddressConverter} from "../../hooks/use-address-converter";
import {Picture}             from "../picture";
import styles                from "./offer-info-window.module.scss";


const OfferInfoWindow: FunctionComponent<{ offer: Offer }> = ({offer}) => {
  return (
    <div className={styles.container}>
      <div className={styles.image}>
        <Picture photoable="offer" sizes={[{size: "160x160"}]} model={offer} type="main"/>
      </div>
      <div className={styles.text}>
        <div>
          <div className={styles.title}>{offer.title}</div>
          <div className={styles.address}>{offer.city}, {useAddressConverter(offer.street, offer.building_nr, offer.apartment_nr)}</div>
        </div>
        <div className={styles.price}>
          <div className={styles.big}>{offer.price} zł</div>
          <div className={styles.small}>{offer.price_per_square_meter} zł/m<sup>2</sup></div>
        </div>
        <Link href={route("offer", {path: offer.path})} title="" className={styles.link}>{$t("general.show_offer")}</Link>
      </div>
    </div>
  );
};

export default OfferInfoWindow;
