import {$t}                          from "@interactivevision/translations";
import {useShared}                   from "@interactivevision/visitor-react";
import classNames                    from "classnames";
import {useEffect}                   from "react";
import React, {useState}             from "react";
import LangSwitcher                  from "../../components/lang-switcher";
import Socials                       from "../../components/socials";
import styles                        from "../partials/navbar.module.scss";
import {useSession, Link}            from "@interactivevision/visitor-react";
import {route}                       from "@interactivevision/routing";
import {ReactComponent as HeartIcon} from "../../../../assets/icons/heart.svg";
import {ReactComponent as UserIcon}  from "../../../../assets/icons/user.svg";
import NavbarMobile                  from "./navbar-mobile";
import {ReactComponent as LogoIcon}  from "../../../../assets/icons/logo.svg";


export const data_menu = {
  en: [
    {href: "homepage_module", name: "About", id: 1},
    {href: "homepage_module", name: "Devices", id: 2},
    {href: "homepage_module", name: "Realisations", id: 3},
    {href: "homepage_module", name: "Projects", id: 4},
    {href: "homepage_module", name: "Cooperation", id: 5},
    {href: "homepage_module", name: "Offer", id: 6},
    {href: "homepage_module", name: "Offer", id: 7},
    {href: "homepage_module", name: "Contact", id: 8},
  ],
  pl: [
    {href: "/o-nas", name: "O nas", id: 2, static: true},
    {href: "realization_module", name: "Realizacje", id: 3},
    {href: "offer_module", name: "Projekty", id: 4},
    {href: "purchase_module", name: "Kupimy", id: 6},
    {href: "/zarabianie", name: "Zarabianie", id: 7, static: true},
    {href: "contact_module", name: "Kontakt", id: 8},
  ],
};

export const Navbar = () => {
  const session             = useSession();
  const [active, setActive] = useState<boolean>(false);
  const [mobile, setMobile] = useState<boolean | undefined>();

  // @ts-ignore
  const {menu} = useShared();


  useEffect(() => {
    if (window && window.innerWidth < 1081) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, []);

  if (mobile === undefined) {
    return null;
  }

  console.log(menu);

  return (
    <>
      <nav className={styles.navbar}>
        <div className={styles.logoWrapper}>
          <Link href={route("homepage_module")} title="Strona główna - MD Inwestor">
            <LogoIcon/>
          </Link>
        </div>
        <div className={styles.menuContent}>
          {!mobile && (
            <div className={classNames(styles.menuListWrapper, {[styles.active]: active})}>
              <ul className={styles.menuList}>
                {
                  menu?.menu_top.map((element, index) => (
                    <li key={index}>
                      <Link target={element.target} rel={element.rel} href={element.url} title={element.name}>{element.name}</Link>
                    </li>
                  ))
                }
              </ul>
            </div>
          )}
          <div className={styles.menuOptions}>
            <div className={styles.menuCol}>
              <Link href={session.is_authenticated ? route("panel.index") : route("auth.login")} title="" className={classNames(styles.menuOptionItem, styles.favourites)}>
                <HeartIcon/>
              </Link>
              {session.is_authenticated ? (
                <Link href={route("panel.index")} title={$t("general.my_account")} className={styles.menuOptionItem}>
                  <UserIcon/>
                  <span>{$t("general.my_account")}</span>
                </Link>
              ) : (
                <Link href={route("auth.login")} title={$t("general.sign_in")} className={styles.menuOptionItem}>
                  <UserIcon/>
                  <span>{$t("general.sign_in")}</span>
                </Link>
              )}
            </div>
            <div className={styles.menuCol}>
              {!mobile ? (
                <>
                  <Socials/>
                  <LangSwitcher/>
                </>
              ) : (
                <div className={styles.hamburgerWrapper}>
                  <button type="button" className={classNames(styles.hamburger, {[styles.active]: active})} onClick={() => setActive(!active)}>
                    <span/>
                    <span/>
                    <span/>
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </nav>
      {
        mobile && (
          <NavbarMobile active={active} navigation={data_menu} setActive={() => setActive(false)}/>
        )
      }
    </>
  );
};

