import {FontSize}                                      from "./base";
import {Base}                                          from "./base";
import {forwardRef, HTMLAttributes, PropsWithChildren} from "react";
import classNames                                      from "classnames";


type PageTitleProps = HTMLAttributes<HTMLHeadingElement> & {
  as?: any;
  size?: FontSize;
}

export const TitleXs = forwardRef<any, PropsWithChildren<PageTitleProps>>((props, ref) => {
  return <Base
    as={props.as || "h3"}
    {...props}
    size={props.size ?? 9}
    weight={500}
    ref={ref}
    className={props.className}
  >{props.children}</Base>;
});

