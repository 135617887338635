import React, {FunctionComponent} from "react";
import SectionDescription         from "../layouts/partials/section-description";
import styles                     from "./block-renderer.module.scss";
import classNames                 from "classnames";
import {Paragraph}                from "../typography/paragraph";


export type BlocksProps = {
  data: any;
  renderers?: any;
};

export type ListItemType = {
  content: string;
  items: ListItemType[];
}

export type ListType = {
  style: string;
  items: ListItemType[];
}

export const List: FunctionComponent<{ data?: ListType, className?: string }> = ({data, className}) => {
  if (!data) {
    return null;
  }

  if (data.style === "ordered") {
    const renderOrdered = (items: ListItemType[]) => {
      return (
        <ol className={classNames(className, styles.list)}>
          {items.map((element, i) => (
            <li key={i}>
              <SectionDescription dangerouslySetInnerHTML={{__html: element.content}}/>
              {element.items.length > 0 && renderOrdered(element.items)}
            </li>
          ))}
        </ol>
      );
    };

    return renderOrdered(data.items);
  }

  const renderNormal = (items: ListItemType[]) => {
    return (
      <ul className={classNames(className, styles.list)}>
        {items.map((element, i) => (
          <li key={i}>
            <SectionDescription dangerouslySetInnerHTML={{__html: element.content}}/>
            {element.items.length > 0 && renderNormal(element.items)}
          </li>
        ))}
      </ul>
    );
  };

  return renderNormal(data.items);
};

export const BlockRenderer: FunctionComponent<BlocksProps> = ({data, renderers = {}}) => {
  const defaultRenderers = {
    paragraph: <SectionDescription />,
    list:      <List/>,
    ...renderers,
  };
  return <>
    {data.blocks.map((block, i) => {
      if (block.type.toString() in defaultRenderers) {
        const Tag = defaultRenderers[block.type];

        const props = {
          key:                     i,
          dangerouslySetInnerHTML: {__html: block.data.text},
        };

        return React.cloneElement(Tag, block.data?.items ? {...props, data: block.data} : {...props});
      }
    })}
  </>;
};
