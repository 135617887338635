export const useAddressConverter = (street: string, building_nr?: string, apartment_nr?: string | null) => {

  if (apartment_nr) {
    if (apartment_nr.charAt(0).match(/[a-z]/i)) {
      return `${street} ${building_nr}${apartment_nr}`;
    } else {
      return `${street} ${building_nr}/${apartment_nr}`;
    }
  }
  return `${street} ${building_nr}`;
};
