import classNames                      from "classnames";
import {useState}                      from "react";
import {ReactNode}                     from "react";
import {FunctionComponent}             from "react";
import React                           from "react";
import {ReactComponent as ChevronDown} from "../../../../assets/icons/chevron-down.svg";
import styles                          from "./offer-tab.module.scss";


type Props = {
  id: string;
  title: string;
  children: ReactNode;
}

const OfferTab: FunctionComponent<Props> = ({id, title, children}) => {
  const [open, setOpen] = useState<boolean>(true);


  return (
    <div className={styles.item} id={id}>
      <div className={styles.header}>
        <div className={styles.title}>
          {title}
        </div>
        <button className={classNames(styles.button, {[styles.open]: open})} onClick={() => setOpen(!open)}>
          <ChevronDown/>
        </button>
      </div>
      {
        open && (
          <div className={styles.content}>
            {children}
          </div>
        )
      }
    </div>
  );
};

export default OfferTab;
