import styles   from "./base.module.scss";
import {Navbar} from "./partials/navbar";

export const auth = ({children}) => {
  return (
    <>
      <Navbar/>
      <main className={styles.container}>
        {children}
      </main>
    </>
  );
};
