import React  from "react";
import styles from "./flag-ue.module.scss";
import Image  from "../../../../public/static/images/ue.png";


const FlagUe = () => {
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <img src={Image} alt=""/>
      </div>
    </div>
  );
};

export default FlagUe;
