import {FunctionComponent}          from "react";
import React                        from "react";
import {BlockRenderer}              from "../../helpers/block-renderer";
import styles                       from "./offer-description.module.scss";
import {ReactComponent as FileIcon} from "../../../../assets/icons/file.svg";
import OfferTab                     from "./offer-tab";


type OfferType = "TEXT" | "ARRAY" | "FILES";

type ArrayProps = {
  label: string;
  value: string;
}

type AttributeProps = {
  id: number;
  title: string;
  type: OfferType;
  data: any | ArrayProps | File[];
}

type Props = {
  attributes: AttributeProps[];
}

const renderer = {
  paragraph: <p className={styles.description}/>,
};

const handleScroll = (id: number) => {
  if (id) {
    document.querySelector(`#tab-${id}`)?.scrollIntoView();
  }
};

const renderNavigation = (attribute: AttributeProps) => {
  return <div className={styles.tab} onClick={() => handleScroll(attribute.id)}>{attribute.title}</div>;
};

const renderContent = (attributes: AttributeProps) => {
  switch (attributes.type) {
    case "TEXT":
      return (
        <OfferTab title={attributes.title} id={`tab-${attributes.id}`}>
          <BlockRenderer data={attributes.data} renderers={renderer}/>
        </OfferTab>
      );
    case "ARRAY":
      return (
        <OfferTab title={attributes.title} id={`tab-${attributes.id}`}>
          {
            attributes.data.map((attribute: ArrayProps, index) => (
              <div key={index} className={styles.attribute}>
                <span className={styles.description}>{attribute.label}</span>
                <span className={styles.description}>{attribute.value ?? "---"}</span>
              </div>
            ))
          }
        </OfferTab>
      );
    case "FILES":
      return (
        <OfferTab title={attributes.title} id={`tab-${attributes.id}`}>
          {attributes.data.map((file, index) => (
            <div key={index} className={styles.file}>
              <FileIcon/>
              <span className={styles.description}>{file.filename}</span>
            </div>
          ))}
        </OfferTab>
      );
  }
};

const OfferDescription: FunctionComponent<Props> = ({attributes}) => {

  return (
    <div className={styles.container}>
      <div className={styles.navigation}>
        <div className={styles.box}>
          {attributes.map((attribute, index) => (
            <React.Fragment key={index}>
              {renderNavigation(attribute)}
            </React.Fragment>
          ))}
        </div>
      </div>
      <div className={styles.wrapper}>
        {attributes.map((attribute, index) => (
          <React.Fragment key={index}>
            {renderContent(attribute)}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default OfferDescription;
