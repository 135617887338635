import {forwardRef, HTMLAttributes, PropsWithChildren} from "react";
import {Base}                                          from "../../typography/base";
import {Paragraph}                                     from "../../typography/paragraph";
import styles                                          from "./section-description.module.scss";
import classNames                                      from "classnames";


type Props = {
  withMarginTop?: boolean;
  withMarginBoth?: boolean;
  centered?: boolean,
}

export type SectionDescriptionProps = HTMLAttributes<HTMLParagraphElement> & Props;

const SectionDescription = forwardRef<any, PropsWithChildren<SectionDescriptionProps>>((props, ref) => {

  const classes = classNames(
    props.className,
    styles.spacer,
    {
      [styles.marginTop]:  props.withMarginTop,
      [styles.marginBoth]: props.withMarginBoth,
      [styles.centered]:   props.centered,
    },
  );

  return (
    <Paragraph {...props} ref={ref} className={classes}>{props.children}</Paragraph>
  );
});

export default SectionDescription;
