import React, {FunctionComponent} from "react";
import {Checkbox}                 from "./basic/checkbox";
import {FormGroup}                from "./form-group";
import {FormInputProps}           from "./form-input";


export const FormCheckbox: FunctionComponent<FormInputProps> = ({required, label, error, name, children, id, ...props}) => {
  return (
    <FormGroup required={required} label={label} error={error}>
      <label htmlFor={id || name}>
        <Checkbox name={name} {...props} required={!label && required} id={id}>
          {children}
        </Checkbox>
      </label>
    </FormGroup>
  );
};
