import classNames                                      from "classnames";
import {Base, FontSize}                                from "./base";
import {forwardRef, HTMLAttributes, PropsWithChildren} from "react";
import styles from './page-title.module.scss';

type PageTitleProps = HTMLAttributes<HTMLHeadingElement> & {
  as?: any;
  light?: boolean;
}

export const PageTitle = forwardRef<any, PropsWithChildren<PageTitleProps>>((props, ref) => {
  return <Base as={props.as || "h1"}  {...props} size={1} weight={600} ref={ref} className={classNames(props.className, {[styles.light]: props.light})}>{props.children}</Base>;
});
