import {route}             from "@interactivevision/routing";
import {$t}                from "@interactivevision/translations";
import {$redirect}         from "@interactivevision/visitor";
import {useSetAtom}        from "jotai";
import {atomFavouriteInfo} from "../atoms/atom-favourite-info";
import {ButtonWithArrow}   from "../components/button";
import {LinkButtonArrow}   from "../components/link-button";
import {Modal}             from "./modal";
import styles              from "./modal-favourites-info.module.scss";
import {FunctionComponent} from "react";


export const ModalFavouritesInfo: FunctionComponent<{ isOpen: boolean, onClose: () => void }> = ({isOpen, onClose}) => {
  const setFavouriteInfo = useSetAtom(atomFavouriteInfo);

  const handleNavigate = (url: string) => {
    $redirect(url);
    setFavouriteInfo(false);
  };

  return (
    <Modal onClose={onClose} isOpen={isOpen} title={$t("general.warning")}>
      <span className={styles.description}>{$t("modal.favourite_info")}</span>
      <ButtonWithArrow onClick={() => handleNavigate(route("auth.login"))}>{$t("general.sign_in")}</ButtonWithArrow>
    </Modal>
  );
};
