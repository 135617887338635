import {Base}                                          from "./base";
import {forwardRef, HTMLAttributes, PropsWithChildren} from "react";
import classNames                                      from "classnames";
import styles                                          from "./title-highlighted.module.scss";


type PageTitleProps = HTMLAttributes<HTMLHeadingElement> & {
  as?: any;
  light?: boolean;
}

export const TitleHighlighted = forwardRef<any, PropsWithChildren<PageTitleProps>>((props, ref) => {
  return <Base
    as={props.as || "h2"}
    {...props}
    size={1}
    weight={600}
    ref={ref}
    className={classNames(styles.title, props.className, {[styles.light]: props.light})}
  >{props.children}</Base>;
});

