import { auth } from '../layouts/auth';
import { contact } from '../layouts/contact';
import { base } from '../layouts/base';


export async function loadAsyncView(name: string) {
  const components = import.meta.glob(`./**/*.tsx`, { eager: false });
  const loader = components[`./${name}.tsx`];

  if (typeof loader === 'undefined') {
    throw new Error(`View "/views/${name}.tsx" was not found!`);
  }

  return resolveDefaultLayout(name, await loader());
}


export async function loadSyncView(name: string) {
  const pages = import.meta.glob('./**/*.tsx', { eager: true });
  const component: any = pages[`./${name}.tsx`];

  if (typeof component === 'undefined') {
    throw new Error(`View "/views/${name}.tsx" was not found!`);
  }

  return resolveDefaultLayout(name, component);
}


function resolveDefaultLayout(name: string, component: any) {
  if (name.includes('auth')) {
    component.default.layout = component.default.layout || auth;
  } else if (name.includes('contact')) {
    component.default.layout = component.default.layout || contact;
  } else {
    component.default.layout = component.default.layout || base;
  }

  return component;
}
