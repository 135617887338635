import aos                   from "aos";
import {useAtom}             from "jotai";
import React                 from "react";
import {useEffect}           from "react";

import "aos/dist/aos.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import {atomFavouriteInfo}   from "../atoms/atom-favourite-info";
import {ModalFavouritesInfo} from "../modal/modal-favourites-info";

import styles   from "./base.module.scss";
import Footer   from "./partials/footer";
import {Navbar} from "./partials/navbar";



export const base = ({children}) => {
  const [favouriteInfo, setFavouriteInfo] = useAtom(atomFavouriteInfo);

  useEffect(() => {
    aos.init({disable: "mobile"});
  }, []);

  return (
    <>
      <Navbar/>
      <main className={styles.container}>
        {children}
      </main>
      <Footer/>
      <ModalFavouritesInfo isOpen={favouriteInfo} onClose={() => setFavouriteInfo(false)}/>
    </>
  );
};
