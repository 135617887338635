import classNames          from "classnames";
import {FunctionComponent} from "react";
import {ReactNode}         from "react";
import React               from "react";
import styles              from "./section-wrapper.module.scss";


interface Props {
  children: ReactNode;
  className?: string;
  small?: boolean;
}

const SectionWrapper: FunctionComponent<Props> = ({children, small, className}) => {
  return (
    <div className={classNames(styles.container, className, {[styles.small]: small})}>{children}</div>
  );
};

export default SectionWrapper;
