import {Base, FontSize, Weight}                        from "./base";
import {forwardRef, HTMLAttributes, PropsWithChildren} from "react";


export type ParagraphProps = HTMLAttributes<HTMLParagraphElement> & {
  weight?: Weight;
  size?: FontSize;
}

export const Paragraph = forwardRef<any, PropsWithChildren<ParagraphProps>>((props, ref) => {
  return <Base as="p" {...props} size={props.size ?? 8} weight={props.weight || 300} ref={ref} className={props.className}>{props.children}</Base>;
});
