import { $t } from '@interactivevision/translations';
import axios from 'axios';
import React, { useState, FunctionComponent, useCallback } from 'react';
import { ReactComponent as ArrowRight } from '../../../assets/icons/arrow-right.svg';
import { Button } from '../components/button';
import { ModalNewsletter } from '../modal/modal-newsletter';
import { FieldCheckbox } from './fields/field-checkbox';
import styles from './form-newsletter.module.scss';
import { FieldInput } from './fields/field-input';
import { Form } from 'react-final-form';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { FormApi } from 'final-form';


export type FormProps = {
  onSubmit: (values: Object, api: any) => any;
  submitting: boolean;
}

const FormWrapper: FunctionComponent<FormProps> = ({ onSubmit, submitting }) => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptchaVerify = useCallback(async (event: any, form: FormApi) => {
    event.preventDefault();

    if (!executeRecaptcha) {
      return;
    }

    const token = await executeRecaptcha('yourAction');

    if (token) {
      form.change('token', token);

      form.submit();
    }
  }, [executeRecaptcha]);

  return (
    <div className={styles.formWrapper}>
      <Form onSubmit={onSubmit} initialValues={{ rodo: false }}>
        {({ handleSubmit, form }) => (
          <form onSubmit={handleSubmit} noValidate>
            <div className={styles.inputWrapper}>
              <FieldInput name="email" placeholder={$t('form.enter_your_email')} className={styles.newsletterInput} required/>
              <Button icon={<ArrowRight/>} type="button" loading={submitting} onClick={(event) => handleReCaptchaVerify(event, form)}>{$t('general.sign_up')}</Button>
            </div>
            <div className={styles.rodo}>
              <FieldCheckbox name="rodo" required>
                {$t('consents.information_clause')}
              </FieldCheckbox>
            </div>
          </form>
        )}
      </Form>
    </div>
  );
};


const FormNewsletter = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [modal, setModal] = useState<boolean>(false);


  const onSubmit = async (values, form) => {
    setLoading(true);

    try {
      await axios.post('/api/newsletter/subscribe', values)
        .then(() => {
          setModal(true);
          setLoading(false);
          form.reset();
        });
    } catch (response: any) {
      setLoading(false);
      return response.response.data.errors;
    }
  };

  return (
    <GoogleReCaptchaProvider reCaptchaKey={import.meta.env.VITE_RECAPTCHA_SITE_KEY}>
      <FormWrapper onSubmit={onSubmit} submitting={loading}/>
      <ModalNewsletter isOpen={modal} onClose={() => setModal(false)}/>
    </GoogleReCaptchaProvider>

  );
};

export default FormNewsletter;
