import React                             from "react";
import {ReactComponent as FacebookIcon}  from "../../../assets/icons/facebook.svg";
import {ReactComponent as InstagramIcon} from "../../../assets/icons/instagram.svg";
import styles                            from "./socials.module.scss";


const Socials = () => {
  return (
    <div className={styles.socials}>
      <a href="https://www.facebook.com/MDInwestor" target="_blank" rel="noopener" title="Facebook">
        <FacebookIcon/>
      </a>
      <a href="#" target="_blank" rel="noopener" title="Instagram">
        <InstagramIcon/>
      </a>
    </div>
  );
};

export default Socials;
