import classNames                                                                                                           from "classnames";
import React, {FunctionComponent, PropsWithChildren, DetailedHTMLProps, ButtonHTMLAttributes, ReactNode, MouseEventHandler} from "react";
import styles                                                                                                               from "./button.module.scss";
import {ReactComponent as ArrowRight}                                                                                       from "../../../assets/icons/arrow-right.svg";


export type BaseButtonProps = {
  /** Styling of the button */
  kind?: "primary" | "secondary";
  /** Stretches the button to it's maximal width */
  fullWidth?: boolean;
  /** Enable rounded corners */
  rounded?: boolean;
  /** No background */
  outlined?: boolean;
  icon?: React.ReactElement | string;
  /** Disables the button */
  disabled?: boolean;
  /** Show a circular progress bar */
  loading?: boolean;
  /** Renders the button highlighted */
  tile?: boolean;
  /** Align the button to a given side */
  /** Component classes */
  className?: string;
};


export type ButtonProps = DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & BaseButtonProps & {
  prompt?: ReactNode;
};

export const Button: FunctionComponent<PropsWithChildren<ButtonProps>> = ({
  type = "button",
  className,
  children,
  disabled = false,
  onClick,
  prompt,
  icon,
  kind = "primary",
  loading = false,
  outlined = false,
  fullWidth = false,
  rounded = false,
  tile = false,

  ...props
}) => {
  const handleOnClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    if (onClick) {
      onClick(e);
    }
  };

  const classes = compileButtonClasses({
    className,
    kind,
    rounded,
    fullWidth,
    outlined,
    loading,
    icon:     !!icon,
    onlyIcon: !!icon && !children,
  });

  return (
    // @ts-ignore
    <button className={classes} disabled={disabled} type={type} onClick={handleOnClick} {...props}>
      {children && (
        <span className={classNames(styles.content, {[styles.invisible]: loading})}>
        {children}
      </span>
      )}
      {icon && icon}
    </button>
  );
};

type ButtonClassCompilerProps = {
  className?: string;
  kind: "primary" | "info" | "success" | "warning" | "danger" | "secondary" | "tertiary";
  rounded: boolean;
  fullWidth: boolean;
  outlined: boolean;
  icon: boolean;
  onlyIcon: boolean;
  loading?: boolean;
};

export function compileButtonClasses(props: ButtonClassCompilerProps) {

  return classNames(
    props.className,
    styles.button,
    styles[props.kind],
    {
      [styles.rounded]:   props.rounded,
      [styles.fullWidth]: props.fullWidth,
      [styles.outlined]:  props.outlined,
      [styles.icon]:      props.icon,
      [styles.onlyIcon]:  props.onlyIcon,
      [styles.loading]:   props.loading,
    },
  );
}

export const ButtonWithArrow: FunctionComponent<PropsWithChildren<ButtonProps>> = (props) => {
  return <Button {...props} icon={<ArrowRight/>}/>;
};
