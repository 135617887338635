import {$t}                from "@interactivevision/translations";
import {Modal}             from "./modal";
import styles              from "./modal-purchase.module.scss";
import {FunctionComponent} from "react";


export const ModalNewsletter: FunctionComponent<{ isOpen: boolean, onClose: () => void }> = ({isOpen, onClose}) => {

  return (
    <Modal onClose={onClose} isOpen={isOpen} title={$t("general.thanks")}>
      <p className={styles.description}>{$t("modal.newsletter_info")}</p>
    </Modal>
  );
};
