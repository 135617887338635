import {route}           from "@interactivevision/routing";
import {$t}              from "@interactivevision/translations";
import {useShared, Link} from "@interactivevision/visitor-react";
import React             from "react";
import FlagUe            from "../../components/flag-ue";
import styles            from "./footer-contact.module.scss";
import SectionWrapper    from "./section-wrapper";


export const FooterContact = () => {
  // @ts-ignore
  const {menu} = useShared();

  return (
    <>
      <footer className={styles.footer}>
        <SectionWrapper className={styles.menu}>
          <ul>
            <li>
              <Link href={route("homepage_module")} title={$t("general.homepage")}>{$t("general.homepage")}</Link>
            </li>
            {
              menu?.menu_top.map((element, index) => (
                <li key={index}>
                  <Link target={element.target} rel={element.rel} href={element.url} title={element.name}>{element.name}</Link>
                </li>
              ))
            }
          </ul>
          <div className={styles.menuBottom}>
            {
              menu?.menu_bottom.map((element, index) => (
                <Link key={index} target={element.target} rel={element.rel} href={element.url} title={element.name}>
                  <span>{element.name}</span>
                </Link>
              ))
            }
          </div>
          <div className={styles.copyrights}>
            © Copyright 2023 MD Inwestor
          </div>
        </SectionWrapper>
      </footer>
      <FlagUe/>
    </>
  );
};

