import {createElement, ComponentType} from "react";
import {RouterProps}                  from "@interactivevision/visitor-react";
import {Cookies}                      from "./cookies/components/cookies";


type SetupOptions = {
  router: ComponentType<RouterProps>;
  props: RouterProps;
}

export default function app({router, props}: SetupOptions) {
  return (
    <>
      {createElement(router, props)}
      <Cookies/>
    </>
  );
};
