import classNames                                      from "classnames";
import {Base}                                          from "./base";
import {forwardRef, HTMLAttributes, PropsWithChildren} from "react";
import styles                                          from "./title-medium.module.scss";


type PageTitleProps = HTMLAttributes<HTMLHeadingElement> & {
  as?: any;
  light?: boolean | undefined;
}

export const TitleMedium = forwardRef<any, PropsWithChildren<PageTitleProps>>((props, ref) => {
  return <Base
    as={props.as || "h2"}
    {...props}
    size={3}
    weight={500}
    ref={ref}
    className={classNames(props.className, {[styles.light]: props.light})}
  >{props.children}</Base>;
});

