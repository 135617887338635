import {FunctionComponent} from "react";
import React               from "react";
import {BlockRenderer}     from "../helpers/block-renderer";
import SectionDescription  from "../layouts/partials/section-description";
import {Paragraph}         from "../typography/paragraph";
import {TitleHighlighted}  from "../typography/title-highlighted";
import {TitleXs}           from "../typography/title-xs";
import styles              from "./why-us-card.module.scss";


type Advantages = {
  props: {
    id: number;
    title: string;
    number: number;
    description: string
  }
  photos: any;
}

const renderers = {
  paragraph: <SectionDescription className={styles.description}/>,
};

const WhyUsCard: FunctionComponent<{ element: Advantages }> = ({element}) => {

  return (
    <div className={styles.wrapper}>
      <div className={styles.iconBox}>
        <div className={styles.iconBox} dangerouslySetInnerHTML={{__html: element.photos[0] && element.photos[0].vector}}/>
      </div>
      <div className={styles.textWrapper}>
        <div className={styles.numberBox}>
          <TitleHighlighted as="p">
            {element.props.number}
          </TitleHighlighted>
        </div>
        <div className={styles.textBox}>
          <div className={styles.titleWrapper}>
            <TitleXs>
              {element.props.title}
            </TitleXs>
          </div>
          <div className={styles.paragraphBox}>
            <p>{element.props.description}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyUsCard;
