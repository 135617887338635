import React, { FunctionComponent } from 'react';
import { Field }                    from 'react-final-form';
import {FormCheckbox}               from "../form-checkbox";
import { FormInputProps }           from '../form-input';


export const FieldCheckbox: FunctionComponent<FormInputProps> = ({ name, label, switchable, ...props }) => {
  return (
    <Field name={name} type="checkbox" value={props.value} parse={x => x}>
      {({ input, meta }) => (
        <FormCheckbox label={label} {...props} {...input} error={meta.submitError}/>
      )}
    </Field>
  );
};
