import {Base}                                          from "./base";
import {forwardRef, HTMLAttributes, PropsWithChildren} from "react";


type PageTitleProps = HTMLAttributes<HTMLHeadingElement> & {
  as?: any;
}

export const TitleBig = forwardRef<any, PropsWithChildren<PageTitleProps>>((props, ref) => {
  return <Base
    as={props.as || "h2"}
    {...props}
    size={2}
    weight={500}
    ref={ref}
    className={props.className}
  >{props.children}</Base>;
});

