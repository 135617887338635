import {useLocation}                          from "@interactivevision/visitor-react";
import {useState}                             from "react";
import {useEffect}                            from "react";
import {FunctionComponent, PropsWithChildren} from "react";
import styles                                 from "./modal.module.scss";
import {ReactComponent as CloseIcon}          from "../../../assets/icons/close.svg";
import classNames                             from "classnames";


type ModalProps = {
  onClose: () => void;
  isOpen: boolean;
  title?: string;
  className?: string;
  top?: boolean;
}
export const Modal: FunctionComponent<PropsWithChildren<ModalProps>> = ({onClose, title = "", isOpen, children, className, top}) => {
  const location                            = useLocation();
  const [openedLocation, setOpenedLocation] = useState<string>(location);

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    setOpenedLocation(location);
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      if (openedLocation !== location) {
        handleClose();
      }
    }
  }, [location]);

  if (!isOpen) {
    return null;
  }

  return (
    <div className={classNames(styles.modal, {[styles.top]: top, [styles.isOpen]: isOpen})}>
      <div className={className ? className : styles.container}>
        {title && <span className={styles.title}>{title}</span>}
        <button className={styles.close} onClick={handleClose}>
          <CloseIcon/>
        </button>
        <div className={styles.content}>
          {children}
        </div>
      </div>
    </div>
  );
};
