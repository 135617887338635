import {$t}              from "@interactivevision/translations";
import {useJsApiLoader}  from "@react-google-maps/api";
import {InfoWindow}      from "@react-google-maps/api";
import {MarkerClusterer} from "@react-google-maps/api";
import {useCallback}     from "react";
import {useState}        from "react";
import React             from "react";
import {GoogleMap}       from "@react-google-maps/api";
import {Marker}          from "@react-google-maps/api";
import {mapStyles}       from "../../data/map-styles";
import CircleImage       from "../../../../../public/static/images/circle.png";
import OfferInfoWindow   from "./offer-info-window";
import styles            from "./offer-map-clusterer.module.scss";


const options = {
  styles: [
    {
      textSize:  16,
      textColor: "white",
      url:       CircleImage,
      height:    40,
      width:     40,
    },
    {
      textSize:  16,
      textColor: "white",
      url:       CircleImage,
      height:    40,
      width:     40,
    },
    {
      textSize:  16,
      textColor: "white",
      url:       CircleImage,
      height:    50,
      width:     50,
    },
  ],
};

const OfferMapClusterer = ({offers}) => {
  const [infoWindow, setInfoWindow] = useState<number | null>(null);
  const [icon, setIcon]             = useState<any>();
  const [infoBox, setInfobox]       = useState<any>();
  const [map, setMap]               = useState(null);

  const {isLoaded} = useJsApiLoader({
    id:               "google-map-script-offer",
    googleMapsApiKey: "AIzaSyAxa6f0fYntIdhYXaE3Kzu3FrQN1Du4bTI",
  });

  const onLoad = React.useCallback((map) => {
    const bounds      = new google.maps.LatLngBounds();
    const coordinates = offers.map((offer) => ({lat: offer.lat, lng: offer.lng}));

    coordinates.forEach((coordinate) => bounds.extend(coordinate));
    map.fitBounds(bounds);

    setIcon({
      anchor:       new google.maps.Point(8, 20),
      fillColor:    "#B1A07C",
      fillOpacity:  1,
      strokeWeight: 0,
      rotation:     0,
      scale:        3,
      path:         "M14.6471 7.70179C14.6471 13.9443 7.82357 18.7997 7.82357 18.7997C7.82357 18.7997 1 13.9443 1 7.70179C1 5.8622 1.71891 4.09796 2.99858 2.79718C4.27824 1.4964 6.01384 0.765625 7.82357 0.765625C9.63329 0.765625 11.3689 1.4964 12.6486 2.79718C13.9282 4.09796 14.6471 5.8622 14.6471 7.70179V7.70179Z",
    });

    setInfobox({pixelOffset: new google.maps.Size(0, -56)});
  }, []);

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  return isLoaded ? (
    <GoogleMap
      mapContainerClassName={styles.map}
      onLoad={onLoad}
      onUnmount={onUnmount}
      onClick={() => setInfoWindow(null)}
      zoom={10}
      options={{
        styles: mapStyles,
      }}
    >
      <MarkerClusterer options={options}>
        {
          (clusterer) =>
            offers.map((offer, index) => (
              <React.Fragment key={index}>
                <Marker
                  icon={icon}
                  onClick={() => setInfoWindow(index)}
                  position={{lat: offer.lat, lng: offer.lng}}
                  clusterer={clusterer}
                  clickable={true}
                />
                {
                  infoWindow === index && (
                    <InfoWindow
                      options={infoBox}
                      position={{lat: offer.lat, lng: offer.lng}}
                      onCloseClick={() => setInfoWindow(null)}
                    >
                      <OfferInfoWindow offer={offer}/>
                    </InfoWindow>
                  )
                }
              </React.Fragment>
            ))
        }
      </MarkerClusterer>
    </GoogleMap>
  ) : (
    <div className={styles.wrapper}>
      <div className={styles.loading}>{$t("general.map_loading")}</div>
    </div>
  );
};

export default React.memo(OfferMapClusterer);
